import { useContext } from "react";
import useSWR from "swr";

import { UserContext } from "../context";
import { URL, Fetcher as F } from "../api";

export const useSchemas = () => {
  const {
    token: { access },
  } = useContext(UserContext);
  const { data: room = {} } = useSWR(
    access && [`OPTIONS ${URL.room}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: bvNote = {} } = useSWR(
    access && [`OPTIONS ${URL.bookVisit.note}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: br = {} } = useSWR(
    access && [`OPTIONS ${URL.book.room}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: brs = {} } = useSWR(
    access && [`OPTIONS ${URL.book.roomSchedule}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: bvSchedule = {} } = useSWR(
    access && [`OPTIONS ${URL.bookVisit.schedule}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: bvCustomer = {} } = useSWR(
    access && [`OPTIONS ${URL.bookVisit.customer}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: bv = {} } = useSWR(
    access && [`OPTIONS ${URL.bookVisit.list}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: customer = {} } = useSWR(
    access && [`OPTIONS ${URL.customer}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: staff = {} } = useSWR(
    access && [`OPTIONS ${URL.staff}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: housing = {} } = useSWR(
    access && [`OPTIONS ${URL.housing}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: housingEvent = {} } = useSWR(
    access && [`OPTIONS ${URL.housingEvent}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: contract = {} } = useSWR(
    access && [`OPTIONS ${URL.contract}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: baby = {} } = useSWR(
    access && [`OPTIONS ${URL.baby}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: babyStatusRecord = {} } = useSWR(
    access && [`OPTIONS ${URL.babyStatus}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: puerpera = {} } = useSWR(
    access && [`OPTIONS ${URL.puerpera}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: changeFoodVendorHistory = {} } = useSWR(
    access && [`OPTIONS ${URL.changeFoodVendorHistory}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: stopMealsHistory = {} } = useSWR(
    access && [`OPTIONS ${URL.stopMealsHistory}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: beverageHistory = {} } = useSWR(
    access && [`OPTIONS ${URL.beverageHistory}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: orderBeverage = {} } = useSWR(
    access && [`OPTIONS ${URL.orderBeverage}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: customerComplaints = {} } = useSWR(
    access && [`OPTIONS ${URL.customerComplaints}`],
    F.withToken,
    { revalidateOnFocus: false }
  );
  const { data: babyLeaveRecord = {} } = useSWR(
    access && [`OPTIONS ${URL.babyLeave}`],
    F.withToken,
    { revalidateOnFocus: false }
  );

  // TODO: use fields
  return {
    room: updateFields(patchRoom(room)),
    puerpera: updateFields(patchPuerpera(puerpera)),
    bookVisitNote: updateFields(patchBookVisitNote(bvNote)),
    bookVisitSchedule: updateFields(patchBookVisitSchedule(bvSchedule)),
    customerBookVisit: updateFields(patchCustomerBookVisit(bvCustomer)),
    bookVisit: updateFields(patchBookVisit(bv)),
    bookRoom: updateFields(patchBookRoom(br)),
    bookRoomSchedule: updateFields(patchBookRoomSchedule(brs)),
    customer: updateFields(patchCustomer(customer)),
    staff: updateFields(patchStaff(staff)),
    housing: updateFields(patchHousing(housing)),
    housingEvent: updateFields(patchHousingEvent(housingEvent)),
    contract: updateFields(patchContract(contract)),
    baby: updateFields(pathcBaby(baby)),
    babyStatusRecord: updateFields(pathcBabyStatusRecord(babyStatusRecord)),
    changeFoodVendorHistory: updateFields(
      patchChangeFoodVendorHistory(changeFoodVendorHistory)
    ),
    stopMealsHistory: updateFields(patchStopMealsHistory(stopMealsHistory)),
    beverageHistory: updateFields(patchBeverageHistory(beverageHistory)),
    orderBeverage: updateFields(patchOrderBeverage(orderBeverage)),
    customerComplaints: updateFields(
      patchCustomerComplaints(customerComplaints)
    ),
    babyLeave: updateFields(patchBabyLeave(babyLeaveRecord)),
  };
};

function patchRoom(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      center_id: {
        ...ui.center_id,
        "ui:widget": "center",
      },
      roomType_id: {
        ...ui.roomType_id,
        "ui:widget": "room-type",
      },
      status: {
        ...ui.status,
        "ui:widget": "switch",
      },
      note: {
        ...ui.note,
        "ui:widget": "note",
        "ui:colspan": 2,
      },
    },
  };
}

function patchBookVisitNote(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      accompany_fever: {
        ...ui.accompany_fever,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      bigbaby_fever: {
        ...ui.bigbaby_fever,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      baby_fever: {
        ...ui.baby_fever,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      accompany_cold: {
        ...ui.accompany_cold,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      bigbaby_cold: {
        ...ui.bigbaby_cold,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      baby_cold: {
        ...ui.baby_cold,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      mom_medical_history: {
        ...ui.mom_medical_history,
        "ui:widget": "medical-history",
        keypath: "name",
      },
      mom_food_allergies: {
        ...ui.mom_food_allergies,
        "ui:widget": "food-allergies",
        keypath: "name",
      },
      is_vegan: {
        ...ui.is_vegan,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      food_comment: {
        ...ui.food_comment,
        "ui:widget": "note",
      },
      checkin_with_mom: {
        ...ui.checkin_with_mom,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
    },
  };
}

function patchBookVisitSchedule(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      is_rotation: {
        ...ui.is_rotation,
        "ui:widget": "checkbox",
      },
      cold: {
        ...ui.cold,
        "ui:widget": "checkbox",
      },
      baby_room: {
        ...ui.baby_room,
        "ui:widget": "checkbox",
      },
      situation: {
        ...ui.situation,
        "ui:widget": "dropdown",
      },
    },
  };
}
function patchCustomerBookVisit(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      created: {
        ...ui.created,
        "ui:widget": "date-time",
      },
      centers: {
        ...ui.centers,
        "ui:widget": "center-list",
      },
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      status: {
        ...ui.status,
        "ui:widget": "customer-visit-status",
      },
      visit_schedule: {
        ...ui.visit_schedule,
        "ui:valuepath": "name",
      },
    },
  };
}

function patchBookVisit(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      centers: {
        ...ui.centers,
        "ui:widget": "center-list",
      },
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      status_id: {
        ...ui.status_id,
        "ui:widget": "visit-status",
      },
      visit_schedule_display: {
        ...ui.visit_schedule_display,
        "ui:widget": "visit_schedule",
      },
      service_employee_id: {
        ...ui.service_employee_id,
        "ui:widget": "employee",
      },
    },
  };
}

function patchBookRoom(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      book_type: {
        ...ui.book_type,
        "ui:widget": "radio",
      },
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
    },
  };
}

function patchBookRoomSchedule(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      book_type: {
        ...ui.book_type,
        "ui:widget": "radio",
      },
      babybeds: {
        ...ui.babybeds,
        "ui:widget": "babybeds",
        "ui:colspan": 3,
      },
      room_id: {
        ...ui.room_id,
        "ui:widget": "room",
      },
      parkingspace_id: {
        ...ui.parkingspace_id,
        "ui:widget": "parking-space",
      },
      cost: {
        ...ui.cost,
        max: 100000,
      },
      contract_id: {
        ...ui.contract_id,
        "ui:widget": "contract",
      },
    },
  };
}

function patchCustomer(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      gender: {
        ...ui.gender,
        "ui:widget": "radio",
      },
      myself: {
        ...ui.myself,
        "ui:widget": "checkbox",
      },
      relationship: {
        ...ui.relationship,
        "ui:widget": "dropdown",
      },
    },
  };
}

function patchContract(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      note: {
        ...ui.note,
        "ui:widget": "note",
      },
    },
  };
}

function patchStaff(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      center_id: {
        ...ui.center_id,
        "ui:widget": "center",
      },
      department_id: {
        ...ui.department_id,
        "ui:widget": "department",
      },
      note: {
        ...ui.note,
        "ui:widget": "note",
      },
      status: {
        ...ui.status,
        "ui:widget": "radio",
      },
    },
  };
}

function patchHousing(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      care_comment: {
        ...ui.care_comment,
        "ui:widget": "note",
      },
      notice: {
        ...ui.notice,
        "ui:widget": "switch",
      },
      foodvendor_id: {
        ...ui.foodvendor_id,
        "ui:widget": "foodvendor",
      },
      cost_postpartum: {
        ...ui.cost_postpartum,
        min: -5000000,
        max: 5000000,
      },
      cost_cyuanya: {
        ...ui.cost_cyuanya,
        min: -5000000,
        max: 5000000,
      },
    },
  };
}

function patchHousingEvent(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      name: {
        ...ui.name,
        "ui:widget": "input",
      },
      status: {
        ...ui.status,
        "ui:widget": "switch",
      },
    },
  };
}

function pathcBaby(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      gender: {
        ...ui.gender,
        "ui:widget": "radio",
        "ui:display": "gender",
      },
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      weight: {
        ...ui.weight,
        "ui:widget": "number",
        max: 30000,
      },
    },
  };
}

function pathcBabyStatusRecord(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      milk_mon: {
        ...ui.milk_mon,
        "ui:widget": "number",
        max: 1000,
      },
      milk_formula: {
        ...ui.milk_formula,
        "ui:widget": "number",
        max: 1000,
      },
      breast_feeding: {
        ...ui.breast_feeding,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      urinate: {
        ...ui.urinate,
        "ui:widget": "number",
        max: 100,
      },
      stool: {
        ...ui.stool,
        "ui:widget": "number",
        max: 100,
      },
      rr: {
        ...ui.rr,
        "ui:widget": "number",
        max: 500,
      },
      hr: {
        ...ui.hr,
        "ui:widget": "number",
        max: 500,
      },
      temperature: {
        ...ui.temperature,
        "ui:widget": "number",
        max: 100,
      },
      weight: {
        ...ui.weight,
        "ui:widget": "number",
        max: 30000,
      },
      guts: {
        ...ui.guts,
        "ui:widget": "number",
        max: 100,
      },
      headsize: {
        ...ui.headsize,
        "ui:widget": "number",
        max: 500,
      },
      infect: {
        ...ui.infect,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      hospitalized: {
        ...ui.hospitalized,
        "ui:display": "boolean",
        "ui:widget": "switch",
      },
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      registrant: {
        ...ui.registrant,
        "ui:widget": "employee",
        disabled: true,
      },
    },
  };
}

function patchPuerpera(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      education: {
        ...ui.education,
        "ui:widget": "dropdown",
      },
      weight: {
        ...ui.weight,
        "ui:widget": "number",
        max: 300,
      },
      height: {
        ...ui.height,
        "ui:widget": "number",
        max: 200,
      },
    },
  };
}

function patchChangeFoodVendorHistory(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      reason: {
        ...ui.reason,
        "ui:widget": "change-food-vendor-reason",
      },
      start_meal: {
        ...ui.start_meal,
        "ui:widget": "meal",
      },
      foodvendor: {
        ...ui.foodvendor,
        "ui:widget": "foodvendor",
      },
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
    },
  };
}

function patchStopMealsHistory(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      stop_meals: {
        ...ui.stop_meals,
        "ui:widget": "foodmeal-list",
      },
    },
  };
}

function patchCustomerComplaints(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      reason: {
        ...ui.reason,
        "ui:widget": "customer-complaints-reason",
      },
    },
  };
}

function patchBeverageHistory(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      report_employee: {
        ...ui.report_employee,
        "ui:widget": "employee",
        disabled: true,
      },
    },
  };
}

function patchOrderBeverage(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      beverage: {
        ...ui.beverage,
        "ui:widget": "input",
      },
      is_order: {
        ...ui.is_order,
        "ui:widget": "switch",
      },
      count: {
        ...ui.count,
        "ui:widget": "number",
      },
    },
  };
}

function patchBabyLeave(o) {
  const { ui = {} } = o;
  return {
    ...o,
    ui: {
      ...ui,
      comment: {
        ...ui.comment,
        "ui:widget": "note",
      },
      reason: {
        ...ui.reason,
        "ui:widget": "leave-reason",
      },
    },
  };
}

function updateFields(o) {
  const { ui = {}, fields = [] } = o;
  return {
    ...o,
    fields: fields.map((field) => ({
      ...field,
      ui: { ...field.ui, ...ui[field.key] },
    })),
  };
}
