import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  Modal,
} from "carbon-components-react";
import { Add16, Edit20 } from "@carbon/icons-react";
import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";

import { DateTimeFormat } from "../../utils/datetime";
import { SchemaContext, UserContext } from "../../context";
import { SchemaEditor } from "../../components/Editor";
import { useUserData } from "../../hooks";
import { URL, Fetcher as F } from "../../api";

const headers = [
  {
    key: "leave_date",
    name: "離房日期",
  },
  {
    key: "back_date",
    name: "回房日期",
  },
  {
    key: "reason",
    name: "原因",
  },
  {
    key: "comment",
    name: "備註",
  },
  {
    key: "record",
    name: "紀錄日期",
  },
  {
    key: "employee_name",
    name: "操作人員",
  },
  {
    key: "actions",
    name: "編輯/取消",
  },
];

export const BabyLeave = () => {
  const { bid } = useParams();

  const {
    token: { access },
  } = useContext(UserContext);

  const basicURL = URL.babyLeave;
  const url = `${basicURL}?expand=reason,keyin_employee`;
  const { data } = useSWR(access && [url], F.withToken);

  const isLoading = !data;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/care">護理管理</BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          新生兒離館紀錄
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>新生兒離館紀錄</Tile>

      {isLoading ? (
        <DataTableSkeleton showHeader={false} />
      ) : (
        <LeaveRecordTable basicURL={basicURL} baby={bid} items={data} />
      )}
    </Content>
  );
};

function LeaveRecordTable({ basicURL, baby, items }) {
  const { babyLeave: schema } = useContext(SchemaContext) || {};
  const {
    token: { access },
  } = useContext(UserContext);
  const user = useUserData();
  const { mutate } = useSWRConfig();

  const cleanData = items.map(({ reason, keyin_employee, ...other }) => {
    return {
      ...other,
      reason: reason?.name ?? "",
      employee_name: keyin_employee?.name ?? "-",
    };
  });

  const [recordData, setRecordData] = useState();

  const [currentRecordID, setCurrentRecordID] = useState(0);

  const [isEditHistory, setIsEditHistory] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState("inactive");

  const isEditTitle = isEditHistory ? "編輯" : "新增";

  const handleCreate = async () => {
    const { id, ...o } = recordData;
    const payload = { ...o };

    setEditModalStatus("active");
    await F.post(`${basicURL}`, payload);
    mutate([basicURL]);
    setOpenEditModal(false);
  };

  const dataURL = `${basicURL}${currentRecordID}`;
  const { data: rawData } = useSWR(
    isEditHistory ? access && [dataURL] : null,
    F.withToken
  );

  useEffect(() => {
    if (!rawData || rawData == null) {
      return;
    }
    setRecordData(rawData);
  }, [rawData]);

  const handleEdit = async () => {
    if (currentRecordID === 0 || !rawData) {
      return;
    }

    const { id, ...o } = recordData;
    const payload = { ...o };

    setEditModalStatus("active");
    const r = await F.put(`${basicURL}${currentRecordID}/`, payload);
    mutate([basicURL + r.id], r, false);
    setOpenEditModal(false);
  };

  const handleCreateOrEdit = async () => {
    isEditHistory ? handleEdit() : handleCreate();
  };

  return (
    <>
      <Modal
        open={openEditModal}
        modalHeading={`${isEditTitle}新生兒離館紀錄`}
        primaryButtonText="確認"
        secondaryButtonText="取消"
        loadingStatus={editModalStatus}
        onRequestClose={() => setOpenEditModal(false)}
        onSecondarySubmit={() => setOpenEditModal(false)}
        onRequestSubmit={handleCreateOrEdit}
      >
        <SchemaEditor
          fields={schema.fields}
          excludes={[
            "id",
            "created",
            "modified",
            "keyin_employee_id",
            "back_keyin_employee",
            "baby",
            "activate_date",
            "deactivate_date",
            "status",
          ]}
          requiredFields={["start_date", "end_date"]}
          data={recordData}
          onChange={(x) => {
            if (!user) return;
            setRecordData({
              ...x,
              baby: baby,
              keyin_employee_id: user.employee_id,
            });
          }}
        />
      </Modal>
      <DataTable rows={cleanData} headers={headers}>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button
                  renderIcon={Add16}
                  onClick={() => {
                    setIsEditHistory(false);
                    setOpenEditModal(true);
                  }}
                >
                  新增離房記錄
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()} isSortable>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      isSortable={header.key !== "actions"}
                    >
                      {header.name}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      const id = cell.id.split(":")[0];
                      let content;
                      switch (header) {
                        case "leave_date":
                        case "back_date":
                        case "record":
                        case "created":
                        case "modified":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "reason":
                        case "comment":
                        case "employee_name":
                          content = cell.value;
                          break;
                        case "actions":
                          content = (
                            <div>
                              <Button
                                kind="ghost"
                                renderIcon={Edit20}
                                iconDescription="編輯"
                                onFocus={() => setCurrentRecordID(id)}
                                onClick={() => {
                                  setIsEditHistory(true);
                                  setOpenEditModal(true);
                                }}
                              />
                            </div>
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
